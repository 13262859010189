<section *ngIf="!isLoading">
<div class="container mt" id="delivery-container">
  <div class="row mb">
    <div class="col-md-12">
      <div class="breadcrumb-con">
        <div class="delivery-breadcrumb">
          <span class="breadcrumb-steps" *ngFor="let obj of breadcrumbSteps;"
            [class.activetab]="selectedIndex === (obj.id)">
            <span *ngIf="checkNav(obj.id); else elseBlock" class="step"
              (click)="switchTab(obj.id)">{{obj.name | translate}}</span>
            <ng-template #elseBlock><span class="step inactivetab">{{obj.name | translate}}</span></ng-template>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <mat-tab-group animationDuration="1000ms" [selectedIndex]="selectedIndex">
        <mat-tab>
          <ng-template mat-tab-label>
            <span class="tab-title">{{'selectService.chooseVehicle'| translate}}</span>
            <span class="tab-hint">{{'selectService.selectVehicle'| translate}}</span>
          </ng-template>
          <div class="delivery-content select-vehicle">
            <app-vehicle-customer-info #form1 [isUpdate]="isEditMode" [signedIn]="signedIn" [profile]="profile"
              [appointmentId]="appointmentId" [checklistDuration]="checklistDuration" [lockSalesConsultant]="lockSalesConsultant" [dealerCode]="dealerCode"></app-vehicle-customer-info>
          </div>

        </mat-tab>
        <!-- <mat-tab [disabled]="!form1?.deliveryForm?.valid">
          <ng-template mat-tab-label>
            <span class="tab-title">Checklist</span>
            <span class="tab-hint">Select Checklist</span>
          </ng-template>
          <div class="delivery-content select-delivery">
            <app-checklist #form2 [isUpdate]="isEditMode"></app-checklist>
          </div>

        </mat-tab> -->
        <mat-tab [disabled]="!form1?.deliveryForm?.valid">
          <ng-template mat-tab-label>
            <span class="tab-title">{{'selectService.appointmentTime'| translate}}</span>
            <span class="tab-hint">{{'selectService.selectTime'| translate}}</span>
          </ng-template>
          <div class="delivery-content">
            <app-sales-appointment-time [transport]="transport" [apptType]="apptType"
              [checklistDuration]="checklistDuration" [lockSalesConsultant]="lockSalesConsultant" [isEditMode]="isEditMode"></app-sales-appointment-time>
          </div>

        </mat-tab>
        <mat-tab [disabled]="true">
          <ng-template mat-tab-label>
            <span class="tab-title">{{'selectService.yourInformation'| translate}}</span>
            <span class="tab-hint">{{'selectService.verifyInfo'| translate}}</span>
          </ng-template>
          <div class="delivery-content">
            <app-confirm-summary></app-confirm-summary>
          </div>

        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<div id="delivery-sticky-footer">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="sticky-breadcrumb">
          <div class="disclaimer" *ngIf="selectedIndex === 2" (click)="disclaimerToggle = !disclaimerToggle"
            [ngClass]="{'expanded': disclaimerToggle}">
            <div class="disclaimer-text">
              {{getDisclaimerText | translate}}
            </div>
            <mat-icon class="toggle-icon">keyboard_arrow_up</mat-icon>
          </div>
          <div class="action-area">
            <div>
              <button (click)="gotoBack()" [ngClass]="selectedIndex !== 0 && !isSubmitting ? 'btn-enable':''"
                class="goback-btn" [disabled]="selectedIndex === 0 || isSubmitting">{{'selectService.back'| translate}}
              </button>
            </div>
            <div class="selection-info desktop spacer">
              <div class="footer-summaries">
                <ng-container *ngFor="let fs of salesAppointmentFooterSummaries$ | async">
                  <ng-container *ngIf="toShowFooterAppointment">
                    <div class="footer-summary" *ngIf="fs.value">
                      <div class="key">
                        {{fs.key | translate}}
                        <span class="disclaimer" *ngIf="fs.disclaimer">*</span>
                      </div>
                      <div class="value">{{fs.value | translate}}</div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>

              <div class="disclaimer-text" *ngIf="checklistDuration">
                <span style="color:red">*</span>
                <span style="color: gray">{{'delivery.theActualDurationMayVaryAtTheDealership' | translate}}</span>
              </div>
            </div>
            <div class="spacer-mobile"></div>
            <div class="continue-btn">
              <button (click)="switchTab()" class="continue" [class.submitting]="isSubmitting"
                [ngClass]="isValidForm() ? 'btn-enable':'btn-disabled-color'" [disabled]="isSubmitting">
                <span>{{(isSubmitting ? continueBtnText[selectedIndex + 1] : continueBtnText[selectedIndex]) |
                  translate}}</span>
                <app-loading *ngIf="isSubmitting" loadingSymbol="dots" color="var(--dashboard-primary-color)" size=".6">
                </app-loading>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</section>
<div *ngIf="isLoading" class="text-center">
  <app-loading size=".5"></app-loading>
</div>